<amplify-authenticator  *ngIf="authState !== 'signedin'">
    <amplify-sign-up
    slot="sign-up"
    usernameAlias="email"
    [formFields]="formFields"
  ></amplify-sign-up>
<div *ngIf="authState === 'signedin' && user">
    <div>Hello, {{user.username}}</div>
</div>
</amplify-authenticator>

<div  *ngIf="authState === 'signedin' && user">
    <h1>Logged in </h1>
    <amplify-sign-out></amplify-sign-out>
</div>
