<br/>
<div class="image-wrapper">
    <img class="responsive-image" src="/assets/poster.png">
</div>
<br/>
<div>
    <h2 class="text-center">Rules</h2>
    <ul>
        <li>Each week players pick one team from the set of premier league fixtures</li>
        <li>If the team wins the player goes through to the next round</li>
        <li>If the team loses or draws the player is out</li>
        <li>Players can only pick each team once during the competition</li>
        <li>Players who are still alive must pick their team before the weeks deadline</li>
        <li>Players who fail to pick a team will be assigned the next alphabetical team available to them</li>
        <li>If a players team has their game postponed the player will qualify for the next round and the team cannot be picked again</li>
        <li>If your chosen team playes twice in a gameweek only the first game will be counted</li>
        <li>If all of the remaining players are eliminated all of these player will go through to the next round</li>
        <li>The decision of the committe is always final</li>
        <li>The Last one standing is the winner</li>
    </ul>
</div>