<mat-tab-group mat-stretch-tabs>
    <mat-tab label="Info">
        <app-info></app-info>
    </mat-tab>
    <mat-tab label="Players">
        <app-leaderboard></app-leaderboard>
    </mat-tab>
    <mat-tab label="Stats">
        <app-stats></app-stats>
    </mat-tab>
    <mat-tab label="Gameweek">
       <app-gameweek></app-gameweek>
    </mat-tab>
   
</mat-tab-group>


